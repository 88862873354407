/**
*
* Formulaires
*
**/

/*========================================
=            Form par default            =
========================================*/

/*----------  Header / Footer  ----------*/

.form--header,  {
	a:not(.btn){
		text-decoration: underline;
		&:hover, &:focus {
		    text-decoration: none;	
		}
	}
	margin-top: 20px;
}
.form--footer{
	margin-bottom: 30px;
	font-size: $font-size-sm;
	a:not(.btn){
		text-decoration: underline;
		&:hover, &:focus {
		    text-decoration: none;	
		}
	}
}
/*----------  Checkbox  ----------*/
.checkbox {}
.checkbox--value {}

/*----------  Checkbox  ----------*/
.radio {}
.radio--value {}

/*----------  Checkbox & Radio other  ----------*/
.checkbox--other, .radio--other {
	label {
		width: auto;
	}
	input[type="checkbox"] {
		margin-top: 8px;
	}
}
	.checkbox--other-input, .radio--other-input {
		width: auto;
    	display: inline;
		margin-left: 1rem;
	}

/*----------  Radios visual  ----------*/
.radio--visual {}
.radio--visual-item {}
.radio--visual-img{}




/*=====  End of Form par default  ======*/

.form-title{
	text-transform: uppercase;
	margin-bottom: 30px;

}

input.form-control{
	height: $input-height;
}

.control-label{
	// position: absolute;
	// top: 15px;
	// left: 30px;
	@extend .cocon;
	font-size: 1.5rem;
	text-transform: uppercase;
	color:$input-placeholder-color;
	transition:all 0.3s ease;
}

.input--filled{
	position: relative;
	.control-label{
		top:-20px;
		left:15px;
		font-size: $font-size-sm;
	}
}
.custom-control-label{
	position: relative;
	top: 0;
	left: 0;
	padding-left: 25px;
}

.other-form{
	padding: 15px;	
	margin-top: 45px;
	border-top: 1px solid $body-color;
	p{
		margin-bottom: 30px;
	}
}

.other-form--link{
	.icomoon{
		font-size: 3.5rem;
		line-height: 0;
		vertical-align: -10px;

	}
}