/*	--------------------------------------------------
	--------------------------------------------------

	Project			: xxx
	Creation date	: xx/xxx
	By				: xxx/xx

	--------------------------------------------------
	--------------------------------------------------*/

@import "_zmixins";

@import "bootstrap/_bootstrap.scss";


/* Modules d'E-majine */
@import "_structure";
@import "_elements";
@import "_account";
@import "_ads";
@import "_board";
@import "_boxes";
@import "_catalog";
@import "_community";
@import "_directory";
@import "_e-commerce";
@import "_navigation";
@import "_publications";

/** Personnalisation **/
//@import "_animate";
@import "_fonts";
@import "_form";
@import "_e-majine";

/* slider */
@import "_slick";

//social wall
@import "_social-wall";

/*fancybox 3*/
@import "jquery.fancybox.min.css";
