/* Social wall */

.box-sw--filters {
	margin-bottom: 30px;
	margin-top: 20px;
	text-align: center;
}

.box-sw--filters-item {
	width: 50px;
	height: 50px;
	cursor: pointer;
	position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius:50%;
    margin: 0 5px;
	&:hover, &:focus {
		&:after {
			transform: translate(-50%,-50%) scale(1.1);
            transition: all .3s ease;
		}
	}

	&:after {
        content: '';
        width: 100%; height:100%;
		background: $gray-300;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		transition: all .3s ease;
        z-index: -1;
        border-radius: 50%;
	}
}
.box-sw--ico{
    color:$body-color;
    font-size: 20px;

}


.socal-wall-active {
	&:after {
		width: 60px;
		height: 60px;
		border-radius: 30px;
	}

	&:after {
		color:  $primary;
	}
}

.sw-item {
	margin-bottom: 30px;
}

.sw-item--link{
	background-color: #fff;
	display: inline-block;
	color: $body-color;
	width: 100%;
	overflow: hidden;

	&:hover, &:focus {
		color: $body-color;
		text-decoration: none;

		.sw-item--img {

			&:before {
	        	opacity: 1;
	        }

        	&:after {
            	opacity: 1;
        	}

        	img {
				transform: scale(1.05);
			}
        }
	}
}

.sw-item--img {
	position: relative;
	text-align: center;
	overflow: hidden;

	&:before {
		content: '';
		background-color: #fff;
		width: 40px;
		height: 40px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		opacity: 0;
		transition: all .4s ease;
		z-index: 2;
    }

    &:after {
		content: '\e209';
		@extend .icomoon;
		color: $primary;
		font-size: 14px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%) rotate(45deg);
		opacity: 0;
		transition: all .4s ease;
		z-index: 3;
    }

	img {
		width: 100%;
		max-width: 100%;
		transition: all .4s ease;
	}
}

.sw-item--container {
	position: relative;
	z-index: 1;
    padding-top: 30px;
}

.sw-item--text  {
	word-wrap: break-word;
	position: relative;
	z-index: 2;
}
.sw-item--container{
    &:before {
        content: '';
        width: 50px;
        height: 50px;
        border-radius: 25px;
        background-color: $gray-400;
        position: absolute;
        top: -25px;
        left: 50%;
        transform: translateX(-50%);
    }

    &:after {
        content: '';
        color: $body-color;
        @extend .icomoon;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        font-size: 25px;
        top:-12px;
    }
}


.sw-item_fb {
	.sw-item--container:after {
		content: '\f09a';
	}
}

.sw-item_youtube {
	.sw-item--container:after {
		content: '\e906';
	}
}

.sw-item_insta {
	.sw-item--container:after {
		content: '\e909';
	}
}

.sw-item_pinterest {
	.sw-item--container:after {
		content: '\e901';
		font-size: 23px;
		top: -12px;
	}
}

.sw-item--date {
	color: $primary;
	margin-top: 10px;
	display: inline-block;
}
.box-sw--list{display: block;}

.sw-item {
    float: left;
  @include make-col-ready();

  @include media-breakpoint-up(sm) {
    @include make-col(6);
  }
  @include media-breakpoint-up(md) {
    @include make-col(4);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(3); 
  }
}

@include media-breakpoint-up(sm) {
    .box-social-wall--filters{
    	margin-top: -60px;
    	text-align: right;
    }
}
