/*	--------------------------------------------------
	--------------------------------------------------
		Catalogue et ses widgets
--------------------------------------------------
--------------------------------------------------*/


/* commons */
.cat {}
.cat--title {}
.cat--desc {}

.cat--list {}
.cat--item {}

.cat--form {}
.cat--label {}
.cat--select {}
.cat--radio {}
.cat--submit {}

.nb-prod {}
.nb-prod--label {}
.nb-prod--select {}
.nb-prod--submit {}

.sort-prod {}
.sort-prod--label {}
.sort-prod--select {}
.sort-prod--label {}
.sort-prod--asc {}
.sort-prod--desc {}
.sort-prod--radio {}
.sort-prod--submit {}

/* publication (/catalog/publication_methods/catalog.html) */
.family--presentation {}
.family--img {}
.family--desc {}
.family--article {}

.family--child-access {}
.family--child-label {}
.family--child-select {}
.family--child-submit {}
.family--child-list {}
.family--child-item {}
.family--child-name {}
.family--child-img {}
.family--child-link {}

/* comments (/catalog/product/product_comments.html) */
/* Attention le commons comments sont dans publication.less */
.prod-comments {}
.prod-comments--title {}
.prod-comments--empty {}
.prod-comments--nb {}
.prod-comments--average {}
.prod-comments--list {}
.prod-comments--list-link {}
.prod-comments--form-link {}

.prod-comment {}
.prod-comment--title {}
.prod-comment--note {}
.prod-comment--txt {}
.prod-comment--author {}
.prod-comment--date {}
.prod-comment--site {}

.prod-comments--form {}
.prod-comments--legend {}
.prod-comments--form-error {} /* Attention correspond a la notification d'erreur unique dans le template commentaire de produit */

/* box search (/catalog/search/box_search.html) */
.box-cat-search {}
.box-cat-search--form {}
.box-cat-search--keywords {}
.box-cat-search--submit {}
.box-cat-search--last{}
.box-cat-search--advanced {}

/* short-product */
.st-prod{
    height: 100%;
}
.st-prod--title{}
.st-prod--link{}
.st-prod--desc{}

/* responsive*/

// Small devices (landscape phones, 34em and up)
@include media-breakpoint-up(sm) {

}

// Medium devices (tablets, 48em and up)
@include media-breakpoint-up(md) {

}

// Large devices (desktops, 62em and up)
@include media-breakpoint-up(lg) {

}

// Extra large devices (large desktops, 75em and up)
@include media-breakpoint-up(xl) {

}
