/*	--------------------------------------------------
	--------------------------------------------------
		Widgets :
	propriétés générales, texte, actu, rss,
	banniere, calendrier, raccourcis, météo, sondage,
	recommander le site, newsletter, publicité

--------------------------------------------------
--------------------------------------------------*/

/* commons */
.box {}
.box--title {}

//no masque les titres des widget avec la classe "no-title"
.no-title .box--title{display: none}

/* text (/boxes/text/box_text.html) */
.box-txt {}

/* shortcuts header (/boxes/shortcut/header_shortcut.html) */
.box-header-links {}
.box-header-links--list {}
.box-header-links--item {}
.box-header-links--link{}

/* shortcuts aside (/boxes/shortcut/tools_shortcut.html) */
.box-aside-links {}
.box-aside-links--list {}
.box-aside-links--item {}
.box-aside-links--link {}

/* shortcuts footer (/boxes/shortcut/footer_shortcut.html) */
.box-footer-links {}
.box-footer-links--list {}
.box-footer-links--item {}
.box-footer-links--link {}

/* last elements (/boxes/last_elements/last_elements.html) */
.box-last-elts {}
.box-last-elts--list {}
.box-last-elts--item {}
.box-last-elts--article {}
.box-last-elts--title {}
.box-last-elts--date {}
.box-last-elts--img {}
.box-last-elts--desc {}
.box-last-elts--link{}

/* newsletter (/newsletters/box_newsletters.html) */
.box-nl {}
.box-nl--desc {}
.box-nl--form {}
.box-nl--label {}
.box-nl--input {}
.box-nl--submit {}


/* search (/search/box_search.html) */
.box-search {}
.box-search--form {}
.box-search--label {}
.box-search--input {}
.box-search--submit {}
.box-search--advanced {}

/* box-social (/z-templates/social.tmpl.html) */
.box-social {
	margin: 30px 0;
	@include media-breakpoint-up(md) {
		margin: 0;
	}

}
.box-social--title{
	display: block;
	margin-right: 5px;
	/* margin-bottom: 30px; */
	position: relative;
	font-size: 1.5rem;
	text-transform: uppercase;
	color:theme-color('primary');
	@extend .cocon;

}
.box-social--list{margin-bottom: 0;}
.box-social--item {
    display: inline-block;
    vertical-align: middle;
}
.box-social--link {
    background: theme-color('primary');
	color: $white;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	line-height: 30px;
	text-align: center;
	display: block;
	text-decoration: none;
	margin: 0 2px;
	font-size: $font-size-sm;

	&:hover, &:focus {
	    	background: theme-color('danger');
	    	color: $white;
	}
}
.box-social--facebook {}
.box-social--twitter {}
.box-social--google {}

/** RESPONSIVE **/
.box-login{
	margin-left: auto;
}
.box-login--user{
	.icomoon{
		vertical-align: -3px; 
		margin-right: 8px;
		font-size: 2rem;
	}
}

.box-links-brochure{
	margin-right: 15px;
	.icomoon{
		vertical-align: -3px; 
		margin-right: 8px;
		font-size: 2rem;
	}
}


.box-coordinates--address{
	position: relative;
	font-size: 1.5rem;
	text-transform: uppercase;
	padding-left: 30px;
	color:theme-color('primary');
	@extend .cocon;

	.icomoon{
		font-size: 2.2rem;
		color:theme-color('danger');
		position: absolute;
		left: 0;
		top: 0;
	}
}
.footer{
	.box-coordinates--address{
		padding-left: 50px;
		@include media-breakpoint-up(md) {
			padding-left: 30px;
		}
		.icomoon{
			left: 15px;
			@include media-breakpoint-up(md) {
				left: 0;
			}
		}
	}
}

.box-coordinates--link{
	display: block;
	margin-bottom: 30px;
	position: relative;
	font-size: 1.5rem;
	text-transform: uppercase;
	color:theme-color('primary');
	@extend .cocon;

	&:hover, &:focus {
    	color:theme-color('danger');
    	.icomoon{
    		color:theme-color('primary');
    	}
	}

	.icomoon{
		color:theme-color('danger');
		font-size: 2.2rem;
		margin-right: 15px;
	}
}

.box-links-footer--link{
	margin-top: 18px;

	&:after{
		color:theme-color('primary');
	}
}

.box-copy{
	text-align: left;
	font-size: 1.2rem;
	color:$gray-600;
	p{
		margin-bottom: 0;
	}

	a{
		&:hover, &:focus {
		    text-decoration: underline;	
		}
	}
}

.box-groupe{
	text-align: right;
	font-size: 1.2rem;
	color:theme-color('primary');
	img{
		margin-left: 15px;
		vertical-align: middle;
	}
	p{
		margin-bottom: 0;
	}
}

.box-links--directory{
	.btn{
		display: block;
		padding: 15px;

		.icomoon{
			margin-right: 10px;
		}

	}
}

.box-coordinates_contact{
	padding: 35px 30px;
	height: 100%;
	margin-bottom: 0;
	background: $dark-blue;
	color:$white;

	.box-title{
		text-transform: uppercase;
	}

	.box-coordinates--address{
		color:$white;
		padding-left: 0;
		& > p{
			margin-bottom: 25px;
			position: relative;
			padding-left: 30px;

			&:before{
				content: "\e60b";
				display: block;
				font-size: 2.5rem !important;
				@extend .icomoon;
				position: absolute;
				top: 0;
				left: 0;
			}
			&.box-coordinates--numbers{
				&:before{
					content: "\e913";
				}
			}
		}
	}
	.box-coordinates--numbers{
		position: relative;
		padding-left: 30px;
		.icon-phone{
			position: absolute;
			top: 0;
			left: 0;
			font-size: 2.5rem !important;
			color:theme-color('danger');
		}
	.box-coordinates--link{
			margin-bottom: 0;
			color:$white;
		}
		.box-coordinates--fax{
			@extend .cocon;
			font-size: 1.5rem;
			text-transform: uppercase;
		}
	}
}

.box-tags-filter--form {
    margin-bottom: 40px;

    .custom-checkbox {
        padding: 0;
        margin-bottom: 10px;

        .custom-control-label {
            padding: 4px 15px;
            cursor: pointer;
            text-transform: uppercase;
            border: 1px transparent solid;
            border-radius: 4px;
            margin-right: 10px;
            transition: all .3s ease;

            &:hover {
                color: $white;
                background-color: $primary;
                border: 1px $white solid;
                outline: none;
            }

            &:before {
                display: none;
            }
        }

        .custom-control-input:checked ~ .custom-control-label {
            color: $white;
            background-color: $primary;
            border: 1px $white solid;

            &::before {
                display: none;
            }
            &::after {
                display: none;
            }
        }
    }

    .box-tags-filter--submit {
        margin-bottom: 10px;

        button {
            text-transform: uppercase;
            padding: 2px 15px;
			border-radius: 5px;

            @include media-breakpoint-up(md) {
                padding: 5px 15px;
            }
        }
    }
}