/*	--------------------------------------------------
	--------------------------------------------------
		Annuaire et ses widgets
--------------------------------------------------
--------------------------------------------------*/

/* box entries (/directory/boxes/entries.html) */
.box-dir {}
.box-dir--list {}
.box-dir--item {}

.work, .reference{
	background: $dark-blue;
	height: 100%;
}
.reference{
	color:$white;
}
.work-link{
	display: block;

	.icon-fleche{
		font-size: 3.5rem;
		transition:all 0.2s ease;
	}
	&:hover, &:focus {
	    .icon-fleche{
	    	transform:translateX(10px);
	    }	
	}
}
.work-img{
	height: 250px;
	> div{
		height: 250px;
	}
	img{
		width: 100%;
		height: 100%;
		object-fit:cover;
	}
}

.reference-img{
	height: 220px;
	overflow:hidden;
	background-color:$white;
	border:solid 1px $primary;

	& > div{
		height: 220px;
		display:flex;
		align-items: center;
		justify-content: center;
	}

	img{
		display:block;
		max-width:160px;
		max-height:90%;
		height:auto;
		margin:0 auto;
	}
}
.reference-title{
	margin-bottom:.5rem;
}
.reference-type{
	ul{
		list-style: none;
	}

	li{
		margin-bottom:0;
		color: $teal;
	}
}
.reference-desc{
	color:$white !important;
	margin-top:1rem;
}

.work-txt, .reference-txt{
	padding: 15px 20px;
	text-align: center;
}
.work-title, .reference-title{
	color:$white;
	font-family: $font-family-base;
	text-transform: uppercase;
	font-size: 2rem;
}
.work-desc, .reference-desc{
	color : $teal;
}

.work-details--desc{
	margin-bottom: 30px;
}

.dir-header{
	text-align: center;
	height: 300px;
    padding-bottom: 50px;
	color:$white;
}
.dir-details--header{
	display: flex;
    align-content: center;
    align-items: center;
    text-align: center;
    height: 300px;
    padding-bottom: 50px;
	color:$white;
}
.dir-presentation--img{
	
	max-width: 80px;
	margin: 15px auto 20px;
	@include media-breakpoint-up(md) {
		max-width: 100%;
		margin: 0 auto 30px;
	}
}
.dir--title{
	position: relative;
	text-transform: uppercase;

	&:after{
		@extend .icomoon;
		content: "\e91e";
		display: block !important;
		margin: 15px auto 0;
		color : $white;
		line-height: 0 !important;
		font-size: 9rem !important;
		
	}
}

.work-details--title{
	text-transform: uppercase;
	//color:$white;
	//text-align: center;
	width: 100%;
}

.work-details--qualification{
	> div{
		
		> div{
			display: flex;	

			@include media-breakpoint-up(md) {
				display: flex;	

			}
		}
		img{
			max-height: 100px;
			margin: 15px 0;
			@include media-breakpoint-up(md) {
				max-height: 60px;
				margin: 0 10px;
			}
		}
	}
}
.work-details--imgs{
	> div{
		
		> div{				
			@include make-row();	
			div{
				height: 167px;
				overflow: hidden;
				position: relative;
				margin-bottom: 30px;

				a{
					height: 167px;
					overflow: hidden;
					position: relative;
					display: block;
				}
				@include make-col-ready();

				@include media-breakpoint-up(sm) {
				    @include make-col(6);
				}
				@include media-breakpoint-up(md) {
				    @include make-col(4);
				}
				@include media-breakpoint-up(lg) {
				    @include make-col(3);
				}

				img{
					position: absolute;
					width: 100%;
				    left: 0;
				    top: 50%;
				    transform: translateY(-50%);
				    height: 100%;
				    object-fit:cover;
				}
			}
		}

	}
}

.work-details--material{
	background: url('/images/fond-marin.jpg')no-repeat 0 0;
	background-size: cover;
	padding: 30px 0;
	margin-top: 30px;
	margin-bottom: 30px;
	color:$white;
	@include media-breakpoint-up(md) {
		padding: 50px 0;
		margin-top: 50px;
		margin-bottom: 50px;
	}
	h2{
		text-align: center;
	}

	.icomoon{
		width: 85px;
		height: 85px;
		line-height: 85px;
		display: block;
		border-radius: 100%;
		margin: 0 auto 15px;
		font-size: 5rem;
		text-align: center;
		border:2px solid $white;

	}

	h3{
		color:$white;
	}
}

.work-details--application-list{
/* 	.col-md-6{
	margin-bottom: 30px;
	@include media-breakpoint-up(md) {
		margin-bottom: 0;
	}
} */
	ul{
		padding: 0;
		list-style-type: none;
		li{
			padding-left: 40px;
			position: relative;
			margin-bottom: 20px;

			&:before{
				font-family: 'icomoon';
				speak: none;
				font-style: normal;
				font-weight: normal;
				font-variant: normal;
				text-transform: none;
				line-height: 1;
			 	 display: inline-block;
				/* Better Font Rendering =========== */
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				position: absolute;
				top: 50%;
				left: 0;
				transform:translateY(-50%);
				content: "\e920";
				margin-right: 10px;
				font-size: 2.8rem;
			}
		}
	}
}
.work-details--othersList {
	display: flex;
	margin-bottom: 2em;
	&Link {
		padding: 5px 20px;
		border-radius: 10px;
		margin-right: 1em;
		transition: 0.3s background-color;
	}
}
.reference-link{
	color:$white;
	&:hover, &:focus {
	    color:$white;	
	}
}

.dir-children--item--title--img{
	padding: 15px;
	text-align: center;
	img{
		max-width: 100px;
	}
}
.dir-children--link{
	display: block;
	background: theme-color('primary');
	text-align: center;
	color:$white;
}

.dir-children--item--title{
	color:$white;
	font-size: 18px;
	text-transform: uppercase;

}
.dir-children--item{
	.icon-fleche{
		font-size: 25px;
	}
}