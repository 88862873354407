/*	--------------------------------------------------
	--------------------------------------------------
		Agencement des blocs structurants :
	container, header, content, sidebar, footer
--------------------------------------------------
--------------------------------------------------*/


.header {
	padding: 10px 0;

	@include media-breakpoint-up(md) {
		padding: 20px 0 15px;
	}
}
.header--boxes{

    position: relative;
    z-index: 9;


    @include media-breakpoint-up(lg) {
    	margin-bottom: -65px;
    }
}
.logo {

}
.nav-logo{
	width: 100px;
	height: 90px;
	color:transparent;
	background: url('/images/logo.svg')no-repeat 0 0;
	background-size: contain;

	.nav-link{
		display: block;
		color:transparent;
		width: 100px;

		&:after{
			display: none !important;
		}
	}

	span{
		display: none;
	}
}

.diapo-main{
	/* position: relative; */
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	overflow: hidden;
	z-index: -1;
	height: 250px;

	@include media-breakpoint-up(md) {
		height: 300px;
	}

	&:before{
		content:"";
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		@extend .gradient;
		z-index: -1;
		
	}

	img{
		/* z-index: -2; */
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		object-fit:cover;
		height: 100%;
		mix-blend-mode: multiply;
	}


	@supports not (mix-blend-mode: multiply) {
        &:before{
            content:"";
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			@extend .gradient;
			z-index: -1;
			opacity: 0.7;                
        }

        img{
			z-index: -2;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
		}
    }
}

.main {
	position: relative;
}
.right-bar {}

.footer {
	background: $gray-100;
	position: relative;
	margin-top: 40px;
	padding-bottom: 10px;

	&:before{
		content:"";
		display: block;
		margin-bottom: 40px;
		width: 100%;
		height: 2px;
		@extend .gradient;
	}
}

.page-home{
	.footer{
		margin-top: 0;
	}
}



