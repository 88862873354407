/*	--------------------------------------------------
	--------------------------------------------------
		méthodes de publication:
	communs, actualités, articles, événements
--------------------------------------------------
--------------------------------------------------*/

/* media (bootstrap) : présentation famille, header news detail, header event detail, structure bloc&image emajine */
.media{
    flex-wrap: wrap;
}
.media-body{}
@include media-breakpoint-up(sm) {
    .media{
        flex-wrap: nowrap;
    }
    .media-img{
        max-width: 25%;
    }
}

/*card (short-product, article list, news list, event-list, social wall, ...)*/
.card{}
.card-body{}
.card-link{}
.card-title{}
.card-text{}

/* commons (multiple) */
.mu {}
.mu--title {}
.mu--list {}

.mu--item {
    background: $dark-blue;
    height: 100%;
}
.mu--item-link {
    display: block;
    color:$white;

    .icon-fleche{
        font-size: 3.5rem;
        transition:all 0.2s ease;
    }
    &:hover, &:focus {
        .icon-fleche{
            transform:translateX(10px);
        }   
    }
}
.mu--item-img {
    height: 250px;
    > div{
        height: 250px;
    }
    img{
        width: 100%;
        height: 100%;
        object-fit:cover;
    }
}
.mu--item-container {
    padding: 15px 20px;
    text-align: center;
}
.mu--item-header {}
.mu--item-title {
    color:$white;
    font-family: $font-family-base;
    text-transform: uppercase;
    font-size: 2rem;
}
.mu--item-infos {}
.mu--item-date {
    color : $teal;
}
.mu--item-desc {
    color : $teal;
}

.mu--archives-link {}

/* news (/publication_methods/news/news.html) */
.news {}
.news--title {}
.news--list {}

.news--item {

}
.news--item-link{}
.news--item-img {
    margin-bottom: 30px;
}
.news--item-container {}
.news--item-header {}
.news-detail--date{
    color:theme-color('danger');
}
.page-home{
    
    .news--item-link{
    display: block;

    .icon-fleche{
        font-size: 4rem;
        transition:all 0.2s ease;
        .path2{
            &:before{
                color:theme-color('primary');
            }
        }
    }

    &:hover, &:focus {
        .icomoon{
            transform:translateX(10px);
        }    
    }
}
    


    .news--item-title {
        text-transform: uppercase;
        color:theme-color('primary');
        font-weight: bold;
        margin-bottom: 5px;
        line-height: 1.2;
        font-size: 2rem;
    }
    .news--item-infos {}
    .news--item-date {
        color:theme-color('danger');
        font-weight: bold;
        font-size: 1.8rem;
        margin-bottom: 15px;
    }
    .news--item-desc {
        color:$teal;
        margin-bottom: 30px;
    }
}

.news--archives-link {}

/* articles (/publication_methods/articles/multi_articles.html) */
.articles {}
.articles--title {}
.articles--list {}

.articles--item {}
.articles--item-link{}
.articles--item-img {}
.articles--item-container {}
.articles--item-header {}
.articles--item-title {}
.articles--item-infos {}
.articles--item-desc {}

.articles--archives-lin {}

/* events (/events/events.html) */
.events {}
.events--title {}
.events--list {}

.events--search {}
.events--search-label {}
.events--search-date {}
.events--search-period-list {}
.events--search-period-item {}
.events--search-period {}
.events--search-type {}
.events--search-keywords {}
.events--search-submit {}
.events--period-link{}
.events--period-prev {}
.events--period-today {}
.events--period-next {}
.events--type-recall {}
.events--keyword-recall {}

.events--item-link {}
.events--item-img {}
.events--item-container {}
.events--item-header {}
.events--item-title {}
.events--item-infos {}
.events--item-date {}
.events--item-type {}
.events--item-desc {}

/* Comments (/community/comments/list_comments.html) */
/* Attention les commentaires sont utilisé dans plusieurs modules.
vous retrouverez les classes spécifique aux comments product dans la fiche de style catalog.less par exemple */
.comments {}
.comments--title {}
.comments--empty {}
.comments--nb {}
.comments--average {}
.comments--moderation {}
.comments--confirm {}
.comments--list {}
.comments--list-link{}
.comments--form-link {}

.comment {}
.comment--title {}
.comment--note {}
.comment--txt {}
.comment--author {}
.comment--date {}
.comment--site {}

/* Comments form (/community/comments/form_add_comment.html ) */
.comments--form {}
.comments--legend {}
.comments--form-error {} /* Attention correspond a la notification d'erreur unique dans le template commentaire de produit */


/** RESPONSIVE **/

.main-activities--item{
    margin: 30px 0;
    text-align: center;


}
.main-activities--link{
    color:$white;

    .icomoon{
        font-size: 4rem;
        transition:all 0.2s ease;
    }

    &:hover, &:focus {
        color:$white;

        .icomoon{
            transform:translateX(10px);
        }
    }
}
.main-activities--item-img{
    text-align: center;
    margin-bottom: 15px;
}
.main-activities--item-title{
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.main-activities--item-desc{
    color:theme-color('success');
}

.main-ref--list-item{
    height: 75px;
    line-height: 75px;
    overflow: hidden;
    text-align: center;

    img{
        max-height: 75px;
        vertical-align: middle;
        display: block;
        margin: 0 auto;
    }
}
.main-ref--list{
    margin: 40px 0;
}

.main-ref--link{
    &:after{
        color:$body-color;
    }
}
.main--link{
    color:$white;
    &:hover, &:focus {
        color:$white;   
    }

    @include media-breakpoint-up(md) {
        padding-left: 0;
    }
    
}

.main-news{
    background: $white;
    padding: 30px 15px;

    @include media-breakpoint-up(md) {
         padding: 30px;
    }

    .subtitle{
        color:$cyan;
    }
}

.main-news--link{
    color:$white;
    &:hover, &:focus {
        color:$white;   
    }

    @include media-breakpoint-up(md) {
        padding-left: 0;
    }
}

.main-site{
    margin-top: 30px;
    @include media-breakpoint-up(md) {
        margin-top: 0;
    }
}
.main-site--img{
    width: 255px;
    height: 255px;
    margin:0 auto;
    overflow: hidden;

    @include media-breakpoint-up(md) {
        width: 150px;
        height: 150px;
    }
    @include media-breakpoint-up(lg) {
         width: 210px;
        height: 210px;
    }
    @include media-breakpoint-up(xl) {
         width: 255px;
        height: 255px;
    }

    img{
        object-fit: cover;
        width: 100%;
        height: 100%;
        
    }
}

.content-header{
    display: flex;
    align-content: center;
    align-items: center;
    justify-content:center;
    text-align: center;
    height: 300px;
    padding-bottom: 50px;
    color:$white;
    position: relative;
    text-transform: uppercase;
    h1{
        &:after{
            @extend .icomoon;
            content: "\e91e";
            display: block !important;
            margin: 15px auto 0;
            color : $white;
            line-height: 0 !important;
            font-size: 9rem !important;
            
        }
    }

    
}
.main--img{
    margin-bottom: 30px;
    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
    }
}