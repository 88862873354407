/**
*
* Eléments réutilisables :
* boutons, icônes, popin (lightbox, notification…), slider (styles communs)…
*
**/
body {
	min-height: 100vh; /* 1 */
	display: flex; /* 2 */
	flex-direction: column; /* 3 */
}

.main {
	flex-grow: 1; /* 4 */
}

/* on évite les débordements */
img {
	max-width: 100%;
}

.map {
	max-width: 100%;
	overflow: hidden;

	> div {
		max-width: 100%;
		overflow: hidden;
	}

}

/* boutons */
.btn-prev {
	&:before {
		content: '\e603';
		@extend .icomoon;
		padding-right: 0.5rem;
		vertical-align: -2px;
	}

}

.btn-next {
	&:after {
		content: '\e602';
		@extend .icomoon;
		padding-left: 0.5rem;
		vertical-align: -2px;
	}

}

/* galerie d'images*/
.article-gallery {
	.lightbox {
		overflow: hidden;
		max-height: 12.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 1.5rem;
		//création d'un colonnage pour les éléments de galerie
		@include make-col-ready();

		@include media-breakpoint-up(sm) {
			@include make-col(6);
		}

		@include media-breakpoint-up(md) {
			@include make-col(4);
		}

		@include media-breakpoint-up(lg) {
			@include make-col(3);
		}

	}

}

/* pagination*/
.pager_current_page {
	@extend .active;
}

/*===================================
=            Alert Oldie            =
===================================*/
.oldie {
	.alert-ie {
		border-top: 10px solid #e44204;

		& > div {
			padding: 20px 70px;
			background: url(/images/alert-ie.png) left center no-repeat;
		}

		strong {
			color: #e44204;
		}

	}

}

/*=====  End of Alert Oldie  ======*/

/*==================================
=            Skip links            =
==================================*/

.skip-nav {
	position: absolute;
	padding: 0;
	list-style: none;
	z-index: 9999;

	a {
		position: absolute;
		left: -7000px;
		padding: 0.5em;
		background: $gray-800;
		color: white;
		text-decoration: none;

		&:focus {
			position: static;
		}

	}

}

/*=====  End of Skip links  ======*/

/*==========================================
=            Cookies validation            =
==========================================*/

.alert-cookie {
	transition: none;
	overflow: hidden;
	text-align: center;
	background-color: $body-color;
	background-color: rgba(0, 0, 0, 0.85);
	color: #fff;
	animation: cookie-anim 1s ease-in;
	padding: 10px;
	margin: 0;

	&.is-hidden {
		animation: none;
		max-height: 0;
		padding: 0;
		margin: 0;
		transition: all 0.5s ease-out;
	}

	&.fixed-bottom {
		position: fixed;
		width: 100%;
		left: 0;
		bottom: 0;
	}

}

@keyframes cookie-anim {
	0% {
		max-height: 0;
		padding: 0;
		margin: 0;
	}

	100% {
		max-height: 70px;
		padding: 10px;
	}

}

.alert-cookie--link,
.alert-cookie--link:visited {
	display: inline-block;
	color: #fff;

	&:hover,
	&:focus {
		color: #fff;
	}

}

.alert-cookie--btn {

	/* Retrouver les class .btn et .btn-primary directement dans le fichier /zephyr/cookie.js */
}

/*=====  End of Cookies validation  ======*/
.main-video {
	position: relative;
    height: 400px;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
  		height: 595px;
  	}
  
  	@include media-breakpoint-up(xl) {
  		height: 750px;
  	}
}

.scroll-down {
	text-align: center;
	position: absolute;
	bottom: 50px;
	left: 50%;
	transform: translateX(-50%);
	text-transform: uppercase;
	@extend .cocon;
	color: $white;
	border: none;
	background: none;
	font-size: 1.8rem;

	.icomoon {
		display: block;
		margin-top: 5px;
		text-align: center;
		font-size: 2.2rem;
	}


	&:outline {
		border: none;
	}

}

.bloc-primary {
	padding: 30px 0;
	background: $dark-blue;
	color: $white;

	@include media-breakpoint-up(md) {
		padding: 40px 0;
	}

	@include media-breakpoint-up(lg) {
		padding: 90px 0;
	}
}

.mainpage-bottom {
	color: $white;
	background: #05324a;

	position: relative;
	z-index: 0;
	padding: 30px 0;

	@include media-breakpoint-up(md) {
		padding: 40px 0;
	}

	@include media-breakpoint-up(lg) {
		padding: 90px 0;
	}

	&:before {
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: url('/images/fond-sous-marin.jpg')no-repeat center center;
		background-size: cover;
		z-index: -1;
		opacity: 0.2;
	}

}

.gradient {
	background: #03354e;
	background: -moz-linear-gradient(left, #03354e 0%, #095273 50%, #03354e 100%);
	background: -webkit-linear-gradient(left, #03354e 0%, #095273 50%, #03354e 100%);
	background: linear-gradient(to right, #03354e 0%, #095273 50%, #03354e 100%);
}

.main-ref {
	padding: 30px 0;

	@include media-breakpoint-up(md) {
		padding: 40px 0;
	}

	@include media-breakpoint-up(lg) {
		padding: 90px 0;
	}
}

.subtitle {
	text-transform: uppercase;
	@extend .cocon;
	letter-spacing: 1px;
	margin-bottom: 20px;
	font-size: 2.5rem;

	@include media-breakpoint-up(md) {
		font-size: 3rem;
	}

	@include media-breakpoint-up(lg) {
		font-size: 4rem;
	}

}

.main-site--link {
	color: $white;

	&:hover,
	&:focus {
		color: $white;
	}

}

.separator {
	.icomoon {
		line-height: 0.5;
		font-size: 13rem;
		@include media-breakpoint-up(md) {
			line-height: 1;
		}

		&:before {
			color: $teal;
		}

	}

}

ul {
	padding-inline-start: 15px;

	li {
		margin-bottom: 10px;
	}

}

.ias-trigger {
	position: relative;
	width: 100%;
	text-align: center;
	margin: 5rem 0;

	.btn-link {
		position: relative;

		&:after {
			color: theme-color('primary');
		}

		&:hover,
		&:focus {
			color: theme-color('danger');
		}

	}

}
.ias-spinner{
	position: relative;
	width: 100%;
	text-align: center;
	margin: 5rem 0;
}

.sk-wave {
	width: 50px;
	height: 40px;
	text-align: center;
	margin: 0 auto;
	font-size: 10px;

	.sk-rect {
		background-color: theme-color('danger');
		height: 100%;
		width: 6px;
		margin: 0 1px;
		display: inline-block;
		-webkit-animation: sk-waveStretchDelay 1.2s infinite ease-in-out;
		animation: sk-waveStretchDelay 1.2s infinite ease-in-out;
	}

	.sk-rect1 {
		-webkit-animation-delay: -1.2s;
		animation-delay: -1.2s;
	}

	.sk-rect2 {
		-webkit-animation-delay: -1.1s;
		animation-delay: -1.1s;
	}

	.sk-rect3 {
		-webkit-animation-delay: -1s;
		animation-delay: -1s;
	}

	.sk-rect4 {
		-webkit-animation-delay: -0.9s;
		animation-delay: -0.9s;
	}

	.sk-rect5 {
		-webkit-animation-delay: -0.8s;
		animation-delay: -0.8s;
	}

}

@-webkit-keyframes sk-waveStretchDelay {
	0%,
	40%,
	100% {
		-webkit-transform: scaleY(0.4);
		transform: scaleY(0.4);
	}

	20% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}

}

@keyframes sk-waveStretchDelay {
	0%,
	40%,
	100% {
		-webkit-transform: scaleY(0.4);
		transform: scaleY(0.4);
	}

	20% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}

}


.code_html{
	a:not(.btn){
		text-decoration: underline;
		&:hover, &:focus {
		    text-decoration: none;	
		}
	}

}

.fullvideo--player{
	object-fit: cover;
    width: 100%;
    height: 100%;
    font-family: "object-fit: cover;";
}

.img-location img{
	object-fit:cover;
	height: 100%;
}

.article-gallery--item{
	height: 200px;
	overflow: hidden;
	margin-bottom: 15px;

	img{
		object-position: center;
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
}