/**
*
* Navigation
* Menu, breadcrumb, pagination, pager, ...
*
**/

/* gestion du tactile quand le menu repasse en desktop */
@include media-breakpoint-up(md) {
  .navbar-nav .nav-item:hover > .dropdown-menu,
  .navbar-nav .nav-item:focus > .dropdown-menu {
    display: block;
  }

  //à tester pour ios (bootstrap gère ca?)
  .no-touch .navbar-nav li:hover > ul.dropdown-menu {
    display: none;
  }

  .no-touch .navbar-nav li.open > ul.dropdown-menu {
    display: block;
  }

  //dropdown
  .navbar-nav .dropdown-menu {
    margin-top: 0;
  }

  //niveau 3 positionné à droite
  .dropdown-menu .dropdown-menu {
    top: 0;
    left: 100%;
  }

}

/*=======================================
=            Animation Menu             =
=======================================*/
@include media-breakpoint-up(lg) {
  .navbar-nav {
    .dropdown {
      &.open,
      &:hover,
      &:focus,
      &.show {
        & > .dropdown-menu {
          -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
        }

      }

    }

    .dropdown-menu {
      transform-origin: top;
      animation-fill-mode: forwards;
      transform: scale(1, 0);
      display: block;
      transition: all 0.2s ease-out;/* top: 60px; */
    }

  }

}

/*=====  End of Animation Menu ======*/
.navbar-nav {
  width: 100%;
  margin-top: 30px;
  justify-content: space-between;
  align-items: center;

  @include media-breakpoint-up(lg) {
    justify-content: space-between;
    align-items: flex-end;
  }

  .dropdown-toggle {
    position: relative;
  }

  > .nav-item {
    text-align: center;
    width: 100%;

    @include media-breakpoint-up(lg) {
      width: auto;
    }

    &:nth-last-of-type(2){
      .dropdown-menu {
        @include media-breakpoint-up(md) {
          right: 0;
          left: auto;
        }

        @media (min-width:1600px) {
          left: 0;
          right: auto;
        }
      }
    }

    > .nav-link {
      letter-spacing: 1px;
      text-transform: uppercase;
      position: relative;
      @extend .cocon;
      padding-bottom: 1rem;
      @include caret;

      &:after {
        transition: all 0.2s ease-out;
        display: inline-block;

        @include media-breakpoint-up(lg) {
          position: absolute;
          bottom: -5px;
          left: 50%;
          transform: translateX(-50%);
        }

      }

      &:hover,
      &:focus {
        color: $white;

        &:after {
          color: theme-color('danger');
        }

      }

    }

    &:hover,
    &.active {
      > .nav-link {
        &:after {
          color: theme-color('danger');
        }

      }

      .active {
        > .nav-link {
          color: theme-color('danger');
        }

      }

    }

    > .dropdown-menu {
      background: $white;
      border-color: $teal;

      .nav-link {
        text-align: center;
        text-transform: uppercase;
        font-size: $font-size-sm;
        padding: 1rem;
        color: theme-color('primary');
        @extend .cocon;

        @include media-breakpoint-up(lg) {
          text-align: left;
        }

        &:hover,
        &:focus {
          color: theme-color('danger');
        }

        &:after {
          display: none;
        }

      }

    }

  }

}

.navbar-brand {
  padding: 0;
  margin: 0;
  width: 100px;

  img {
    width: auto;
    height: auto;
  }

}

.navbar-nav {
  @include media-breakpoint-up(lg) {
    font-size: 1.5rem;
  }

  @include media-breakpoint-up(xl) {
    font-size: $font-size-base;
  }

}